<template>
  <div class="p-5" v-if="params != false">
    <b-card>
      <b-row class="m-3">
        <b-col md="3" offset-md="3" class="p-4 text-center text-dark rounded" style="background-color: #c9f7f5;">
          <p class="h5">Total commission partenaire:<br> {{formatPrice(total_partenaires)}}</p>
        </b-col>
        <b-col md="3" class="p-4 text-center text-dark ms-5 rounded" style="background-color: #c9f7f5;">
          <p class="h5">Total commission apporteur:<br> {{formatPrice(total_apporteurs)}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col  offset-md="2" md="2">
          <b-form-group label="Mois">
            <b-form-input type="month" v-model="filters['mois']"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Apporteur">
            <treeselect v-model="apporteurSelect" :multiple="true" :options="params.Apporteur"/>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Partenaire">
            <treeselect v-model="partenaireSelect" :multiple="true" :options="params.Partenaire"/>
          </b-form-group>
        </b-col>
        <b-col md="12" class="text-center mt-2">
          <b-button variant="primary" class="text-white" @click="init()"><i class="fas fa-search"></i></b-button>
        </b-col>
      </b-row>
      <b-row class="m-3 mt-5">
        <p class="text-dark h5">Commission partenaires</p>
        <b-col md="12">
          <b-table-simple striped bordered hover class="text-center" small caption-top responsive>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>Raison sociale</b-th>
                <b-th>Nombre souscription</b-th>
                <b-th>CA.</b-th>
                <b-th>Fiche</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(p, i) in partenaires" :key="i">
                <b-td>{{getRaison(p._id.model, p._id.model_id)}}</b-td>
                <b-td>{{p.nb_souscription}}</b-td>
                <b-td>{{formatPrice(p.montant_ht)}}</b-td>
                <b-td><b-button size="sm" variant="indigo" class="text-white" :href="'/#/admin/partenaire/detail/'+p._id.model_id" style="background: #6610f2;"><i class="fas fa-eye"></i></b-button></b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-th>{{partenaires.length}} partenaires</b-th>
              <b-th>{{souscription_partenaires}}</b-th>
              <b-th>{{formatPrice(total_partenaires)}}</b-th>
            </b-tfoot>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row class="m-3 mt-5">
        <p class="text-dark h5">Commission apporteur</p>
        <b-col md="12">
          <b-table-simple striped bordered hover class="text-center" small caption-top responsive>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>Raison sociale</b-th>
                <b-th>Nombre souscription</b-th>
                <b-th>CA.</b-th>
                <b-th>Fiche</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(a, i) in apporteurs" :key="i">
                <b-td>{{getRaison(a._id.model, a._id.model_id)}}</b-td>
                <b-td>{{a.nb_souscription}}</b-td>
                <b-td>{{formatPrice(a.montant_ht)}}</b-td>
                <b-td><b-button size="sm" variant="indigo" class="text-white" :href="'/#/admin/apporteur/detail/'+a._id.model_id" style="background: #6610f2;"><i class="fas fa-eye"></i></b-button></b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-th>{{apporteurs.length}} partenaires</b-th>
              <b-th>{{souscription_apporteurs}}</b-th>
              <b-th>{{formatPrice(total_apporteurs)}}</b-th>
            </b-tfoot>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "AdminCommission",
  components: {
    Treeselect
  },
  data(){
    return {
      params:false,
      apporteurs:[],
      total_apporteurs:0,
      souscription_apporteurs:0,
      partenaires:[],
      total_partenaires:0,
      souscription_partenaires:0,
      apporteurSelect:[],
      partenaireSelect:[],
      filters:{
        "mois":null,
        "_id.model":null,
        "_id.model_id": null
      }
    }
  },
  watch:{
    apporteurSelect(v){
      this.filters['_id.model'] = "Apporteur";
      this.filters['_id.model_id'] = v;
    },
    partenaireSelect(v){
      this.filters['_id.model'] = "Partenaire";
      this.filters['_id.model_id'] = v;
    }
  },
  methods: {
    formatPrice(prix){
      const euro = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      });
      return euro.format(prix)
    },
    getRaison(model, model_id){
      var type = false
      if(model === "Apporteur"){
        type = this.params.Apporteur
      }else if (model === "Partenaire") {
        type = this.params.Partenaire;
      }
      console.log(type);
      if(!type){
        return ""
      }
      var check = type.find(e => e.id === model_id);
      console.log(check);
      if(check === undefined){
        return ""
      }
      return check.raison_social;
    },
    init() {
      var sort = {
        key: 'montant_ht',
        value: 'DESC'
      };
      var params = {
        filters:this.filters,
        sort:sort
      }
      this.$store.api.ajax('/commission/liste', params, res => {
        this.apporteurs = [];
        this.total_apporteurs = 0;
        this.souscription_apporteurs = 0;
        this.partenaires = [];
        this.total_partenaires = 0;
        this.souscription_partenaires = 0;
        res.data.forEach((item) => {
          if(item._id.model === "Apporteur"){
            this.apporteurs.push(item);
            this.total_apporteurs += item.montant_ht;
            this.souscription_apporteurs += item.nb_souscription;
          }else if (item._id.model === "Partenaire"){
            this.partenaires.push(item)
            this.total_partenaires += item.montant_ht;
            this.souscription_partenaires += item.nb_souscription;
          }
        })
      })
    },
  },
  beforeMount() {
    //do something before mounting vue instance
    this.$store.api.ajax('/commission/params', null, res => {
      if(res.status){
        res.data.Apporteur.forEach((item) => {
          item.label = item.raison_social
        });
        res.data.Partenaire.forEach((item) => {
          item.label = item.raison_social
        });
        this.params = res.data
      }
    })
  },
  mounted() {
    this.init();
  }
}
</script>
<style lang="scss" scoped>
</style>
